var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("input", {
        ref: "imgUrl",
        staticClass: "d-none",
        attrs: { type: "file", name: "imgUrl", id: "imgUrl" },
        on: { change: _vm.onChange }
      }),
      _c("div", {
        staticClass: "profile-img",
        style: {
          backgroundImage: "url(" + _vm.$lib.cdnUrl(_vm.userData.imgUrl) + ")"
        }
      }),
      _c(
        "div",
        {
          staticClass: "profile-btn",
          on: {
            click: function($event) {
              return _vm.userImgUrlChange()
            }
          }
        },
        [_vm._v("사진교체")]
      ),
      _c("div", { staticClass: "ui-border-line ui-mt-4" }),
      _c("div", { staticClass: "ui-inputbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.nickName,
              expression: "formData.nickName"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "이름을 입력해주세요",
            size: "15",
            required: ""
          },
          domProps: { value: _vm.formData.nickName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "nickName", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "ui-border-line ui-mb-4" }),
      _c("footer-box", {
        attrs: {
          submitText: "업데이트",
          submitCb: function() {
            return _vm.updateNickName()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }