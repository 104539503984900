<template>
  <div class="container">
    <input type="file" ref="imgUrl" name="imgUrl" id="imgUrl" class="d-none" @change="onChange">

    <div class="profile-img" :style="{ backgroundImage: `url(${$lib.cdnUrl(userData.imgUrl)})` }"></div>

    <div class="profile-btn" @click="userImgUrlChange()">사진교체</div>

    <div class="ui-border-line ui-mt-4"></div>

    <div class="ui-inputbox">
      <input type="text" v-model="formData.nickName" placeholder="이름을 입력해주세요" size="15" required>
    </div>

    <div class="ui-border-line ui-mb-4"></div>

    <footer-box
      submitText="업데이트"
      :submitCb="() => updateNickName()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    FooterBox,
  },
  data() {
    const userData = JSON.parse(JSON.stringify(this.$store.state.user.userData))

    return {
      userData: userData,
      formData: {
        nickName: userData.nickName || '',
        imgUrl: null,
      },
    }
  },
  computed: {
    validate: {
      get() {
        return !this.formData.nickName === false
      },
    },
  },
  mounted() {
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      window.injectUserImgUrl = Vue.injectUserImgUrl
    })
  },
  beforeRouteLeave(to, from, next) {
    window.injectUserImgUrl = () => false
    next()
  },
  methods: {
    userImgUrlChange() {
      this.$runToApp({
        appCb: () => location.href = 'hbscheme://getPicture?params=injectUserImgUrl',
        webCb: () => this.$refs.imgUrl.click(),
      })
    },
    onChange(e) {
      let target = e.target
      let files = target.files
      let reader = new FileReader()
      let formData = new FormData()

      reader.onload = () => {
        formData.set('imgUrl', files[0])
        this.userData.imgUrl = reader.result
        this.formData.imgUrl = reader.result

        this.uploadImg(formData)
        target.value = ''
      }

      if (files[0]) reader.readAsDataURL(files[0])
    },
    uploadImg(formData) {
      if (!formData.get('imgUrl')) {
        log('upload 실패')
        return
      }

      const req = {
        method: 'post',
        url: `/user/img`,
        data: formData
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.updateImg()
            } else {
              this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    injectUserImgUrl(url) {
      if (!url) return false
      const formData = new FormData()

      this.userData.imgUrl = this.$lib.cdnUrl(url)
      this.formData.imgUrl = url
      this.$forceUpdate()

      formData.set('imgUrl', url)
      this.uploadImg(formData)
      return true
    },
    updateImg() {
      this.$toast('프로필 이미지가 수정되었습니다')
      this.$store.commit('setUserData', {
        imgUrl: this.userData.imgUrl
      })
      if (this.userData.category === 'artist') {
        // 미사용 화면임
        //this.$store.commit('setArtistData', { teamImgUrl: this.userData.imgUrl })
      }
    },

    /**
     * 닉네임 변경
     */
    updateNickName() {
      if (!this.userData.nickName) return

      const req = {
        method: 'put',
        url: `/user/nickName`,
        data: {
          nickName: this.formData.nickName
        },
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('프로필이 수정되었습니다')
            this.$store.commit('setUserData', {
              nickName: this.formData.nickName
            })
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-img {
  margin: 2rem auto;
  width: 10rem;
  height: 10rem;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #eee;
}
.profile-btn {
  margin: 0 auto;
  width: 8.5rem;
  // padding: 0 1.4rem;
  border: 1px solid #5a5a5a;
  color: #5a5a5a;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.6rem;
  border-radius: 0.6rem;
}
</style>
